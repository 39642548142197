
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Cam extends Vue {
  private mediaUrl = process.env.VUE_APP_MEDIA_URL;
  private menu_destacado: any = "";
  private menuLateral: any = [];
  private nombreWeb: any = "";
  private selected: any = null;
  private verModal: boolean = false;
  private listaCentros = [
    {
      nombre: "CAM Poniente",
      direccion: "Campanario N.º 2679",
      telefonos: "22 677 6768 - 22 677 6796",
      horario: "Lunes a viernes / 8:30 a 14:00 horas",
      pagina: "https://www.municipalidadmaipu.cl/cam-poniente",
      imagen:
        process.env.VUE_APP_MEDIA_URL + "/media/imagenes/2021/09/cam-poniente-v2.jpeg",
      servicios: [
        {
          descripcion: "Subsidios",
          operativos: []
        },
        {
          descripcion: "Atención Social",
          operativos: []
        },
        {
          descripcion: "Estratificación Social",
          operativos: []
        },
        {
          descripcion: "Maipú Emprende - OMIL",
          operativos: []
        },
        {
          descripcion:
            "Emisión de Certificados: Nacimiento, Matrimonio, Defunción y Antecedentes (con clave única); Afiliación a FONASA  (con clave única); Certificados Estudio Mineduc, Certificados de Deuda y Derechos de Aseo",
          operativos: []
        },
        {
          descripcion:
            "Asesoría legal (lunes a jueves de 08:30 a 17:30 horas / viernes de 08:30 a 14:00 horas): orientación sobre divorcios, herencias, pensión de alimentos - familia, así como temas penales y laborales; derivaciones y charlas",
          operativos: []
        },
        {
          descripcion:
            "Biblioteca (lunes a jueves de 08:30 a 17:30 horas / viernes de 08:30 a 16:30 horas): inscripción y préstamos de libros",
          operativos: []
        },
        {
          descripcion:
            "Zoonosis (todos los lunes y viernes de 08:30 a 13 horas)",
          operativos: []
        }
      ],
      talleres: [
        {
          descripcion: "Zumba (lunes, miércoles y viernes de 10 a 11 horas)",
          operativos: []
        },
        {
          descripcion:
            "Entrenamiento Funcional (martes y jueves de 9 a 10 horas)",
          operativos: []
        },
        {
          descripcion: "Yoga (martes y jueves de 15:30 a 16:30 horas)",
          operativos: []
        }
      ],
      servicios_externos: [
        {
          descripcion:
            "Registro Civil (60 cupos por operativo): emisión de pasaporte, cédula de identidad y clave única (Horario de atención: De 10 a 13 horas)",
          operativos: [
            { fecha: "21 de septiembre" },
            { fecha: "18 de octubre" },
            { fecha: "17 de noviembre" },
            { fecha: "13 de diciembre" }
          ]
        }
      ]
    },
    {
      nombre: "CAM Pajaritos",
      direccion: "Av. Los Pajaritos N.° 4760, Paradero 10",
      imagen:
        process.env.VUE_APP_MEDIA_URL + "/media/imagenes/2021/09/cam-pajaritos.jpeg",
      telefonos: "22 402 8239 - 224028244 - 224028486 - 224028238",
      pagina: "https://www.municipalidadmaipu.cl/cam-pajaritos",
      horario: "",
      servicios: [
        {
          descripcion: "Subsidios",
          operativos: []
        },
        {
          descripcion: "Atención Social",
          operativos: []
        },
        {
          descripcion: "Estratificación Social",
          operativos: []
        },
        {
          descripcion: "Maipú Emprende – OMIL",
          operativos: []
        },
        {
          descripcion:
            "Emisión de Certificados: Nacimiento, Matrimonio, Defunción y Antecedentes (con clave única); Afiliación a FONASA  (con clave única); Certificados Estudio Mineduc, Certificados de Deuda y Derechos de Aseo",
          operativos: []
        },
        {
          descripcion:
            "Asesoría legal (lunes a jueves de 08:30 a 17:30 horas/ viernes de 08:30 a 14:00 horas): orientación sobre divorcios, herencia, pensión de alimentos – familia, temas penales y laborales, derivaciones y charlas",
          operativos: []
        },
        {
          descripcion:
            "Zoonosis (todos los miércoles a partir del 22 de septiembre de 08:30 a 13 horas)",
          operativos: []
        },
        {
          descripcion: "Biblioteca (de 08:30 a 13 horas)",
          operativos: []
        },
        {
          descripcion: "OIRS (reclamos y/o sugerencias)",
          operativos: []
        },
        {
          descripcion:
            "Cursos de Alfabetización Digital (9 a 13 horas) / Módulos de Internet",
          operativos: []
        }
      ],
      talleres: [
        {
          descripcion:
            "Baile Entretenido (lunes, miércoles y viernes de 10 a 11 horas)",
          operativos: []
        },
        {
          descripcion:
            "Pilates (lunes, miércoles y viernes de 11:30 a 12:30 horas)",
          operativos: []
        },
        {
          descripcion: "Yoga (martes y jueves de 10 a 11 horas)",
          operativos: []
        },
        {
          descripcion:
            "Entrenamiento Funcional (martes y jueves de 15:30 a 16:30 horas)",
          operativos: []
        },
        {
          descripcion:
            "Atención Kinesiólogo Adultos Mayores /apoyo CESFAM (lunes y miércoles de 15 a 16:30 horas)",
          operativos: []
        }
      ],
      servicios_externos: [
        {
          descripcion:
            "Registro Civil (60 cupos por operativo): emisión de pasaporte, cédula de identidad y clave única. Horario de atención: De 10 a 13 horas",
          operativos: [
            { fecha: "01 de septiembre" },
            { fecha: "05 de octubre" },
            { fecha: "03 de noviembre" },
            { fecha: "01 de diciembre" }
          ]
        }
      ]
    },
    {
      nombre: "CAM Ciudad Satélite",
      direccion: "Av. Parque Central Oriente N.º 100",
      imagen:
        process.env.VUE_APP_MEDIA_URL + "/media/imagenes/2021/09/cam-satelite.jpeg",
      telefonos: "22 402 8247 - 22402 8170 - 22 402 8256 - 22 402 8264",
      pagina: "https://www.municipalidadmaipu.cl/cam-satelite",
      horario: "",
      servicios: [
        {
          descripcion: "Subsidios",
          operativos: []
        },
        {
          descripcion: "Atención Social",
          operativos: []
        },
        {
          descripcion: "Estratificación Social",
          operativos: []
        },
        {
          descripcion: "Maipú Emprende – OMIL",
          operativos: []
        },
        {
          descripcion:
            "Emisión de Certificados: Nacimiento, Matrimonio, Defunción y Antecedentes (con clave única); Afiliación a FONASA (con clave única); Certificados Estudio Mineduc, Certificados de Deuda y Derechos de Aseo",
          operativos: []
        },
        {
          descripcion:
            "Asesoría legal (martes y jueves de 08:30 a 17:30 horas): orientación sobre divorcios, herencias, pensión de alimentos – familia, temas penales y laborales, derivaciones y charlas",
          operativos: []
        },
        {
          descripcion:
            "Biblioteca (lunes a jueves de 08:30 a 17:30 horas; jueves de 08:30 16:30 horas): inscripción y préstamos de libros",
          operativos: []
        },
        {
          descripcion: "OIRS (reclamos y/o sugerencias)",
          operativos: []
        },
        {
          descripcion:
            "Zoonosis (todos los martes a partir del 21 de septiembre, de 9 a 13 horas)",
          operativos: []
        }
      ],
      talleres: [
        {
          descripcion: "Zumba (martes y jueves de 18:30 a 19:30 horas)",
          operativos: []
        },
        {
          descripcion:
            "Entrenamiento Funcional (lunes, miércoles y viernes de 18:30 a 19:30 horas)",
          operativos: []
        },
        {
          descripcion:
            "Yoga (martes y jueves de 9 a 10 horas y lunes, miércoles y viernes de 17:30 a 18:30 horas)",
          operativos: []
        },
        {
          descripcion:
            "Atención Kinesiólogo Adultos Mayores /apoyo CESFAM (lunes y miércoles de 15 a 17 horas)",
          operativos: []
        }
      ],
      servicios_externos: [
        {
          descripcion:
            "Registro Civil (60 cupos por operativo): emisión de pasaporte, cédula de identidad y clave única. Horario de atención: De 10 a 13 horas",
          operativos: [
            { fecha: "24 de septiembre" },
            { fecha: "22 de octubre" },
            { fecha: "22 de noviembre" },
            { fecha: "17 de diciembre" }
          ]
        }
      ]
    },
    {
      nombre: "CAM Valle Verde",
      direccion: "Alaska N.°1391, Villa Islas del Sol",
      telefonos: "22 402 8595 – 22 402 82 14 - 22 402 8213 - 22 402 8721",
      pagina: "https://www.municipalidadmaipu.cl/cam-valle-verde",
      imagen:
        process.env.VUE_APP_MEDIA_URL + "/media/imagenes/2022/03/cam-valle-verde.jpeg",
      horario: "",
      servicios: [
        {
          descripcion: "Atención Social (jueves de 08:30 a 14:00 horas)",
          operativos: []
        },
        {
          descripcion: "Subsidios (lunes a viernes de 08:30 a 14 horas)",
          operativos: []
        },
        {
          descripcion: "Estratificación Social (miércoles 08:30 a 13:30 horas)",
          operativos: []
        },
        {
          descripcion:
            "Maipú Emprende – OMIL (lunes a viernes de 08:30 a 17:30 horas; viernes de 08:30 a 16:30 horas)",
          operativos: []
        },
        {
          descripcion:
            "Asesoría legal (miércoles de 08:30 a 17:30 horas y viernes 08:30 a 14:00 horas): orientación sobre divorcios, herencias, pensión de alimentos – familia, temas penales y laborales, derivaciones y charlas",
          operativos: []
        },
        {
          descripcion:
            "Zoonosis (jueves de 09:30 a 13 horas, a partir del 23 de septiembre)",
          operativos: [
            { fecha: "23 de septiembre" },
            { fecha: "07 y 21 de octubre" },
            { fecha: "04 y 18 de noviembre" },
            { fecha: "02 y 16 de diciembre" }
          ]
        }
      ],
      talleres: [
        {
          descripcion:
            "Autovalente Adulto Mayor (martes de 09:30 a 11:30 horas)",
          operativos: []
        },
        {
          descripcion: "Pilates (lunes y viernes de 10 a 11 horas",
          operativos: []
        }
      ],
      servicios_externos: [
        {
          descripcion:
            "Registro Civil (60 cupos por operativo): emisión de pasaporte, cédula de identidad y clave única. Horario de atención: De 10 a 13 horas",
          operativos: [
            { fecha: "14 de septiembre" },
            { fecha: "15 de octubre" },
            { fecha: "12 de noviembre" },
            { fecha: "06 de diciembre" }
          ]
        }
      ]
    },
    {
      nombre: "CAM El Maitén",
      direccion: "Av. Central N.° 141",
      imagen:
        process.env.VUE_APP_MEDIA_URL + "/media/imagenes/2021/09/cam-maiten-2.jpeg",
      telefonos: "22 677 6787 - 22402 8061 - 22 677 6703 - 22 402 8149",
      pagina: "https://www.municipalidadmaipu.cl/cam-el-maiten",
      horario: "",
      servicios: [
        {
          descripcion:
            "Subsidios (miércoles de 08:30 a 14 horas, tardes consultas)",
          operativos: []
        },
        {
          descripcion: "Atención Social (08:30 a 14 horas, tarde consultas)",
          operativos: []
        },
        {
          descripcion:
            "Estratificación Social (08:30 a 14 horas, tardes consultas)",
          operativos: []
        },
        {
          descripcion: "Maipú Emprende – OMIL  (08:30 a 17:30 horas)",
          operativos: []
        },
        {
          descripcion:
            "Asesoría legal (viernes de 08:30 a 14:00 horas): orientación sobre divorcios, herencias, pensión de alimentos – familia, temas penales y laborales, derivaciones y charlas",
          operativos: []
        },
        {
          descripcion:
            "Zoonosis (jueves de 9 a 13 horas a partir del 30 de septiembre)",
          operativos: [
            { fecha: "30 de septiembre" },
            { fecha: "14 y 18 de octubre" },
            { fecha: "11 y 25 de noviembre" },
            { fecha: "09 y 23 de diciembre" }
          ]
        }
      ],
      talleres: [],
      servicios_externos: [
        {
          descripcion:
            "Registro Civil (60 cupos por operativo): emisión de pasaporte, cédula de identidad y clave única. Horario de atención: De 10 a 13 horas",
          operativos: [
            { fecha: "09 de septiembre" },
            { fecha: "12 de octubre" },
            { fecha: "08 de noviembre" },
            { fecha: "03 de diciembre" }
          ]
        }
      ]
    }
  ];

  private mounted() {
    document.title =
      "Centros de Atención Municipal / CAM - Municipalidad de Maipú";
    this.setMenu();
  }

  private irLink(link: any) {
    window.open(link, "_blank");
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig2 = await fetch("/menu_destacado.json");
    this.menu_destacado = await runtimeConfig2.json();

    for (let i = 0; i < this.menu_destacado.length; i++) {
      if (
        this.menu_destacado[i].nombre_web == "centros-de-atencion-municipal"
      ) {
        this.menuLateral = this.menu_destacado;
        break;
      }
    }
  }

  private verDetalle(nombre: string) {
    for (let i = 0; i < this.listaCentros.length; i++) {
      if (nombre == this.listaCentros[i].nombre) {
        this.selected = this.listaCentros[i];
        this.verModal = true;
      }
    }
  }

  private selectedStyle(item: any) {
    if ("centros-de-atencion-municipal" == item.nombre_web) {
      item.expand = true;
      return " background-color: #10174a !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
    } else {
      let childSelected = false;
      if (item.sub_menu && item.sub_menu.length > 0) {
        for (let i = 0; i < item.sub_menu.length; i++) {
          if ("centros-de-atencion-municipal" == item.sub_menu[i].nombre_web) {
            childSelected = true;
          }
        }
        if (childSelected) {
          item.expand = true;
          return " background-color: #10174a !important; color: #f1f1f1 !important;font-family: 'RubikBold' !important;";
        }
      }
      return "";
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "centros-de-atencion-municipal") {
      this.routerGo("Concejo");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }
}
